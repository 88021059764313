@import '../../css/common/variables';

.wrapper {
  padding: 0;
}

.active {
  padding: 1rem 1rem 1.25rem 1rem;
  background-color: rgba($danger, 0.25);
  border: 1px solid $danger;
  border-radius: $border-radius;

  p {
    &:first-of-type {
      margin-top: 0;
    }
  }
}

.dangerInput {
  background: rgba($primary, 0.1) !important;
}
