@import '../../css/common/variables';

.noteTextarea {
  max-height: 22rem;
  resize: vertical;
}

.readOnly {
  width: 100%;
  padding: $form-padding;
  margin-top: 0.5rem;
  border-radius: $border-radius;
  background: rgba($primary, 0.05);
  white-space: pre-wrap;
}

.textareaContainer {
  position: relative;
  &.textareaLocked {
    border-bottom: none;
    > textarea {
      resize: none;
    }
  }
}

.paywallOverlay {
  position: absolute;
  top: 0.5rem;
  left: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 2.5rem;
  background: rgba($primary, 0.05);
  z-index: 1;
  > p:first-child {
    margin-top: 0;
    text-align: center;
    font-size: 1rem;
  }
  > button {
    border-radius: $border-radius !important;
  }
}
