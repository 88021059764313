@import '../../css/common/variables';

.center {
  display: flex;
  justify-content: center;
}

.btnWrapper {
  display: flex;
  justify-content: center;
  gap: 1rem;
  flex-direction: row;
  margin: 3rem 0 2rem;
}
