@import '../../css/common/variables';

.center {
  display: flex;
  justify-content: center;
}

.headline {
  text-align: center;

  @media print {
    display: none;
  }
}

.exportWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3rem;
}

.exportText {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  font-size: 0.875rem;
  margin: 0.75rem auto 0;
}

.btnWrapper {
  margin-top: 3rem;
  text-align: center;
}

.btn:not(:last-of-type) {
  margin-right: 1rem;
}

@media print {
  .exportWrapper,
  .btnWrapper {
    display: none;
  }
}
