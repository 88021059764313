@import '../../css/common/variables';
@import '../../css/common/functions';

.wrapper {
  padding: 0;
}

.currentPlanWrapper {
  @media (min-width: 576px) {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    min-width: 20rem;
  }
}

.headline {
  color: $contrast;
  font-size: 1rem;
  margin-bottom: 0.25rem;
}

.wrapperLeft {
  margin: 0 0 1.5rem;
  @media (min-width: 576px) {
    margin: 0 1rem 0 0;
  }
}

.currentPlanText {
  margin: 0.75rem 0 0.25rem;
  color: $primary;
  font-style: italic;
  text-transform: uppercase;
  font-weight: $font-weight-semibold;
  font-size: 0.875rem;
}

.endsText {
  font-size: 0.75rem;
  font-style: italic;
  color: $blue-dark;
}

.paymentMethodWrapper {
  margin-top: 3rem;
}

.invoicesWrapper {
  margin-top: 3rem;
}

.invoicesContainer {
  padding: 0.5rem 0;
  background-color: rgba($primary, 0.05);
  border-radius: $border-radius;

  > p {
    margin: 0.5rem 0 0 0;
    padding: 0 0.5rem;
    text-align: center;
    color: $blue-dark;
    font-size: 0.875rem;

    &:first-child {
      margin: 0;
    }
  }
}

.invoicesLoadingContainer {
  width: 100%;
  height: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.invoice {
  display: block;
  color: $contrast;
  padding: 0.5rem 1rem;
  background-color: rgba($primary, 0);
  transition: background-color 0.25s ease;
  cursor: pointer;

  &:hover {
    background-color: rgba($primary, 0.1);
    text-decoration: none;
  }
}

.invoiceComment {
  margin-top: 0.25rem;
  font-weight: $font-weight-regular;
  font-size: 0.875rem;
}

.invoicesInfoText {
  font-size: 0.875rem;
  line-height: 1.3;
}
