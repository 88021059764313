@import '../../../css/common/variables';

.wrapper {
  margin-bottom: 6rem;
}

.headline {
  text-align: center;
  margin: 0 auto;
}

.textFormContainer {
  margin-top: 2rem;
  @media (min-width: 992px) {
    display: flex;
  }
}

.infoText {
  flex-basis: 50%;
  @media (min-width: 992px) {
    margin-right: 3rem;
  }

  hr {
    border-color: $off-white;
    background-color: $off-white;
  }
}

.result {
  margin-top: 3rem;
}

.noResult {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-height: 4.5rem;
  margin-top: 3rem;

  > span {
    font-size: $font-size-h3-mobile;
    font-weight: $font-weight-light;
    line-height: 1;
    @media (min-width: 768px) {
      font-size: $font-size-h3;
    }
  }

  > svg {
    margin-top: 0.25rem;
    margin-left: 1rem;
    width: 4rem;
    height: 4rem;

    path {
      stroke-width: 4;
    }
  }
}

.backBtnContainer {
  text-align: center;
  margin-top: 2rem;
}
