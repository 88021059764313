@import '../../../css/common/variables';

.matchEntry {
  background-color: $contrast;
  border-radius: $border-radius;
  color: $not-black;
  margin-bottom: 1rem;
}

.matchHeader {
  display: flex;
  align-items: center;
  padding: 0.75rem 1.5rem;
  border: none;
  appearance: none;

  .col {
    margin: 0 0.75rem;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  .dropdown {
    flex: 0 0 1rem;
  }

  .similarity {
    flex: 0 0 6.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .name {
    flex: 1 0 10rem;
  }

  .city {
    flex: 0 0 10rem;
  }

  .country {
    flex: 0 0 2.25rem;
  }

  .listAbbreviation {
    flex: 0 0 7rem;
  }
}

.dropdownBtn {
  display: inline-block;
  padding: 0.75rem;
  margin: -0.5rem;
  appearance: none;
  border: none;
  background: none;
  cursor: pointer;
  transition: opacity 0.25s ease;

  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }

  &:hover {
    opacity: 0.65;
  }

  svg {
    transition: transform 0.25s ease;
  }
}

.dropdownBtnOpened {
  svg {
    transform: rotate(90deg);
  }
}

.similarityIndicatorContainer {
  display: flex;
  align-items: stretch;
  width: 1.875rem;
  height: 1rem;
  margin-left: 0.5rem;
  margin-right: 2rem;
  border: 1px solid $red;
  border-radius: 0.125rem;
  overflow: hidden;
}

.similarityIndicator {
  display: inline-block;
  width: 0;
  background-color: $red;
}

.similarityIndicator1 {
  width: 20%;
}

.similarityIndicator2 {
  width: 40%;
}

.similarityIndicator3 {
  width: 60%;
}

.similarityIndicator4 {
  width: 80%;
}

.similarityIndicator5 {
  width: 100%;
}

.matchDetails {
  display: none;
}

.matchDetailsOpened {
  display: block;
}

.matchDetailsSection {
  padding: 1.5rem 0;
  margin: 0 1.5rem;
  border-bottom: 1px solid $grey-light;

  h3 {
    font-size: 1.125rem;
    font-weight: $font-weight-bold;
  }

  :global(.form-field .control input),
  :global(.form-field .control textarea) {
    color: $not-black;
  }
}
