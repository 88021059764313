@import '../../css/common/variables';

.wrapper {
  width: 560px;
  max-width: 100%;
  margin: 0 auto;
  text-align: center;

  form {
    width: 300px;
    max-width: 100%;
    margin: 3rem auto 0;
  }

  p {
    font-size: 1.25rem;
  }
}

.otherActions {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin: 3rem 0 1rem;
  @media (min-width: 768px) {
    flex-direction: row;
  }
  *:only-child {
    // If there's only one item, we want to center it
    margin: 0 auto;
  }

  @media (max-width: 767.98px) {
    a {
      margin-bottom: 1.5rem;
      &:last-of-type {
        margin: 0;
      }
    }
  }
}
