@import '../../css/common/variables';

.container {
  max-width: 52rem;
  margin: 0 auto;
  print-color-adjust: exact !important;
  -webkit-print-color-adjust: exact !important;
}

.header {
  display: none;
  flex-direction: row;
  align-items: flex-end;
  margin-bottom: 1rem;

  @media print {
    display: flex;
    color: $not-black;
    margin-bottom: 1.5rem;
  }
}

.headerLogo {
  width: 120px;
  height: auto;
  align-self: center;
}

.headerHeadline {
  margin-left: auto;
  font-weight: bold;
  font-size: 1.125rem;
  text-align: right;
}

.result {
  display: flex;
  align-items: center;
  justify-content: stretch;
  gap: 2rem;
  margin-bottom: 1.5rem;
  padding: 1.5rem 2rem;
  border-radius: $border-radius;
  background-color: $contrast;
  color: $not-black;

  @media print {
    background: $off-white;
  }

  a {
    color: inherit;
  }
}

.resultLeft {
  flex: 0 0 12rem;
}

.productName {
  font-weight: bold;
  font-size: 1.5rem;
  margin-bottom: 0.25rem;
  word-break: break-word;

  @media print {
    font-size: 1.125rem;
  }
}

.date {
  font-weight: 600;
  font-size: 1rem;

  @media print {
    font-size: 0.75rem;
  }
}

.resultRight {
  flex: 1 1 auto;
  padding: 1rem;
}

.resultHeadline {
  font-size: 1.375rem;
  font-weight: bold;

  @media print {
    font-size: 0.875rem;
  }
}

.resultText {
  vertical-align: middle;

  @media print {
    font-size: 0.75rem;
  }

  > p:first-child {
    margin-top: 0;
  }

  > ul {
    list-style-position: inside;
    padding-left: 0.5rem;
  }

  a {
    text-decoration: underline;
  }
}

.noteHeadline {
  margin-top: 1rem;
}

.noteText {
  font-style: italic;
}

.resultIcon {
  width: 120px;
  height: auto;

  @media print {
    width: 60px;
  }
}

.questionsTable {
  width: 100%;
  margin: 1.5rem 0 3rem;

  td p:first-child {
    margin-top: 0;
  }
  td:first-of-type,
  th:first-of-type {
    width: 60%;
  }
  td:last-of-type,
  th:last-of-type {
    width: 40%;
    padding-left: 1.5rem;
    word-break: break-word;
  }
}

.footer {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  margin-top: 1.5rem;
  padding: 1.25rem;
  background-color: rgba($primary, 0.1);
  border-radius: $border-radius;
  page-break-inside: avoid;

  @media print {
    background-color: $off-white;
    color: $not-black;
  }
}

.footerContainer {
  flex-grow: 1;
}

.footerHeadline {
  margin-bottom: 1rem;
  font-weight: bold;
}

.footerIcon {
  flex: 0 0 auto;
  width: 3rem;
  height: auto;
  align-self: flex-start;

  @media print {
    color: $primary;
    width: 2rem;
  }
}

.footerContactContainer {
  display: flex;
  margin-top: 1rem;
  > div {
    flex-basis: 50%;
  }

  a {
    color: inherit;
    text-decoration: underline;
  }
}
