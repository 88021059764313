@import '../../../css/common/variables';

.formContainer {
  flex: 1 0 50%;
}

.form {
  :global(option) {
    color: $not-black;
  }

  :global(.form-field) {
    min-width: 0;
  }
}
