@import '../../../css/common/variables';

.wrapper {
  max-width: 30rem;
  margin: 0 auto;
}

.isHitContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.5rem;
  margin-bottom: 0.75rem;

  .isHitOption {
    flex: 1 0 0;
  }

  .isHitToggle {
    flex: 0 0 auto;
  }

  + :global(.help.is-danger) {
    color: $danger;
  }
}

.isHitOption {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition-property: background-color, color;
  padding: 0.5 0.75rem;
  opacity: 0.4;
  cursor: pointer;
  transition: opacity 0.25s ease;

  &:hover {
    opacity: 1;
  }

  &.isHitOptionActive {
    opacity: 1;
  }
}

.isHitIcon {
  background-color: rgba($primary, 0.25);
  height: 2rem;
  width: 2rem;
  margin: 0 0.5rem;
  border-radius: $border-radius;
  transition: 0.25s ease background-color;

  > svg {
    width: 100%;
    height: 100%;
  }
}

// TODO fix border when green
.isHitToggle {
  input[type='checkbox']:checked::after {
    background-color: #d26565;
  }

  input[type='checkbox'] {
    background-color: #80bd64;

    &:not(:checked):indeterminate {
      background-color: $white;
      &::before {
        left: 13px;
        background-color: $primary;
      }
    }

    &::before {
      background-color: $white;
    }
  }
}

.btnContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 2rem 0;

  @media (min-width: 768px) {
    flex-direction: row;
  }

  :global(.btn) {
    flex: 0 0 auto;
    min-width: 0;
    margin: 1rem 0;
    @media (min-width: 768px) {
      margin: 0 0.75rem;
    }
  }
}
