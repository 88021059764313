@import '../../css/common/variables';

.tutorial {
  background-color: $contrast;
  border-radius: 0.5rem;
  color: $not-black;
  .tutorial-wrapper {
    align-items: center;
    padding: 1rem 1.5rem;

    @media (min-width: 768px) {
      padding: 2rem 4.5rem 4rem 3.5rem;
    }
  }

  .flag-wrapper {
    flex-shrink: 0;
    width: 72px;
    height: 72px;
  }

  .head-wrapper {
    display: flex;
    align-items: center;

    .question {
      line-height: 1.35;
      font-weight: $font-weight-semibold;
      margin: 0 0 0.25rem 0.5rem;
    }
  }

  .body-wrapper {
    .title {
      margin: 1.5rem 0 1rem;
    }

    .btn {
      margin-top: 1rem;
    }
  }

  .plan-badge-container {
    margin-top: 2rem;
  }
}
